import { Button, Stack, Typography } from "@mui/material";
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom";
import { acceptInviteToHousehold, InviteInfo, viewInviteToHousehold } from "../data";
import { useState } from "react";
import { useAuthContextControl } from "../contexts/AuthProvider";

export interface LoaderData {
    token: string;
    inviteInfo?: InviteInfo;
}

export async function loader({ params }: LoaderFunctionArgs): Promise<LoaderData> {
    const token = params.token;
    if (!token) {
        throw new Error("Missing recipeId");
    }

    try {
        const inviteInfo = await viewInviteToHousehold(token);
        return {
            token,
            inviteInfo
        };
    }
    catch (e) {
        console.error(e);
        return { token };
    }
}

export default function JoinView() {
    const { token, inviteInfo } = useLoaderData() as LoaderData;
    const navigate = useNavigate();
    const authControl = useAuthContextControl();
    const [error, setError] = useState<string | undefined>(undefined);

    const onJoin = () => {
        acceptInviteToHousehold(token).then(() => {
            authControl?.refresh().then(() => {
                navigate("/profile");
            });
        })
        .catch(e => {
            console.error(e);
            setError(e);
        });
    };

    if (!inviteInfo || error) {
        return <Stack spacing='15px' marginTop="50px" alignItems='center'>
            <Typography variant="h6">{error || "Invalid join link"}</Typography>
        </Stack>;
    }

    return <Stack spacing='15px' marginTop="50px" alignItems='center'>
        <Typography variant="h6">Invitation to join household</Typography>
        <Typography>{inviteInfo.household_name}</Typography>
        <Button variant="contained" onClick={onJoin}>Join</Button>
    </Stack>;
}

