import * as React from 'react';
import './AppBar.css';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '../icons/menu.png';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import { AuthStatus, useAuthContext, useAuthContextControl } from '../contexts/AuthProvider';
import { Household, User, imageIdToUrl } from '../data';
import { Divider, Stack } from '@mui/material';


interface PageEntry {
    text: string;
    href: string;
    needReload?: boolean;
}

const pages: PageEntry[] = [
    { text: 'Hem', href: '/' },
    { text: 'Recept', href: '/recipes' },
    { text: 'Schema', href: '/calendar' },
    { text: 'Memes', href: '/memes' },
];
const settings: PageEntry[] = [
    { text: 'Profile', href: '/profile' },
    { text: 'Logout', href: '/logout', needReload: true }
];

interface HouseholdUserControlProps {
}

function HouseholdUserControl({}: HouseholdUserControlProps) {
    const auth = useAuthContext();
    const authControl = useAuthContextControl();
    const [anchorElHousehold, setAnchorElHousehold] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const user: User | undefined = auth.status === AuthStatus.SignedIn ? auth.user : undefined;
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const currentHousehold = user?.households.find(h => h.id === currentHouseholdId);

    const handleOpenHouseholdMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElHousehold(event.currentTarget);
    };

    const handleCloseHouseholdMenu = (household: Household) => {
        authControl?.setCurrentHousehold(household.id);
        setAnchorElHousehold(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return <Box>
        <Stack direction='row' spacing='15px'>
            <Tooltip title="Change houshold">
                <IconButton onClick={handleOpenHouseholdMenu} sx={{ p: 0 }}>
                    <Avatar alt={currentHousehold?.name}>{currentHousehold?.name?.slice(0, 2)}</Avatar>
                </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem sx={{ borderColor: "white" }}/>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.name} src={imageIdToUrl("thumb", user?.avatar_image_id)} />
                </IconButton>
            </Tooltip>
        </Stack>
        <Menu
            sx={{ mt: '45px' }}
            id="menu-households-appbar"
            anchorEl={anchorElHousehold}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElHousehold)}
            onClose={handleCloseHouseholdMenu}
        >
            <Typography sx={{ ml: "15px", mb: "10px", mt: "15px"}}>Välj hushåll</Typography>
            <Divider variant='middle' sx={{mb: "15px"}}></Divider>
            {user?.households.map((houshold) => (
                <MenuItem key={houshold.id} onClick={() => handleCloseHouseholdMenu(houshold)} selected={currentHousehold === houshold}>
                    <Typography textAlign="center">{houshold.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            {settings.map((setting) => (
                <MenuItem key={setting.text} component={setting.needReload ? "a" : Link} href={setting.href} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
            ))}
        </Menu>
    </Box>;
}

function AppBar() {
    const location = useLocation();
    const auth = useAuthContext();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const user: User | undefined = auth.status === AuthStatus.SignedIn ? auth.user : undefined;

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <MuiAppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MATPLANERING
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <img src={MenuIcon} height={"40px"}></img>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map(({text, href}) => (
                                <MenuItem component={Link} key={text} href={href} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{text}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MATPLANERING
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map(({text, href}) => (
                            <Button
                                key={text}
                                href={href}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <span className={ (location.pathname === href) ? 'app-bar-selected-item' : ''}>
                                    {text}
                                </span>
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <HouseholdUserControl></HouseholdUserControl>
                    </Box>
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
}
export default AppBar;