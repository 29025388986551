import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './views/App';
import Recipes from './views/Recipes';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RecipeView, { loader as recipeLoader } from './views/RecipeView';
import EditRecipeView, { loader as editRecipeLoader } from './views/EditRecipeView';
import CalendarView from './views/CalendarView';
import PlanView, { loader as planViewLoader } from './views/PlanView';
import Meme from './components/Meme';
import ShoppingListView, { loader as shoppingListViewLoader } from './components/ShoppingListView';
import Root from './components/Root';
import ProfileView from './components/ProfileView';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material';
import JoinView, { loader as joinViewLoader } from './components/JoinView';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children: [
      { index: true, element: <App/> },
      {
        path: "/join/:token",
        element: <JoinView/>,
        loader: joinViewLoader,
      },
      {
        path: "/profile",
        element: <ProfileView/>,
      },
      {
        path: "/recipes",
        element: <Recipes/>,
      },
      {
        path: "/recipe/:recipeId",
        element: <RecipeView/>,
        loader: recipeLoader,
      },
      {
        path: "/recipe-edit",
        element: <EditRecipeView/>,
        loader: editRecipeLoader,
      },
      {
        path: "/calendar",
        element: <CalendarView/>,
      },
      {
        path: "/plan",
        element: <PlanView/>,
        loader: planViewLoader
      },
      {
        path: "/shopping-list",
        element: <ShoppingListView/>,
        loader: shoppingListViewLoader
      },
      {
        path: "/memes",
        element: <Meme/>
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
