import React, { ReactElement } from 'react';
import { CooklangNumber as ICooklangNumber, CooklangRecipe, QuantityValue as IQuantityValue, ScalableValue as IScalableValue, Step } from '../data';
import "./Recipe.css";
import { RecipeDisplayContext, ScaleTarget, useRecipeDisplayContext } from '../contexts/RecipeDisplayContext';
import { Box, Divider, Stack } from '@mui/material';
import { buildEditRecipe } from './RecipesTable';

function scaleCooklangNumber(value: ICooklangNumber, scale: ScaleTarget): ICooklangNumber {
    let valueToScale: number;
    switch (value.type) {
        case "regular":
            valueToScale = value.value;
            break;
            
        case "fraction":
            const { whole, num, den } = value.value;
            valueToScale =  whole + num / den;
            break;
    }

    
    return { type: 'regular', value: valueToScale * scale.factor };
}

function scaleQuantityValue(value: IQuantityValue, scale?: ScaleTarget): { value: IQuantityValue, scaled: boolean } {
    if (!scale) {
        return { value, scaled: false };
    }

    switch (value.type) {
        case "number":
            return { value: { type: 'number', value: scaleCooklangNumber(value.value, scale) }, scaled: true };

        case "range":
            const start = scaleCooklangNumber(value.start, scale);
            const end = scaleCooklangNumber(value.end, scale);;
            return { value: { type: 'range', start, end }, scaled: true };

        case "text":
            return { value, scaled: false };
    }
}

function CooklangNumber({ value, className }: { value: ICooklangNumber; className?: string; }) {
    const { fractionAsNumber } = useRecipeDisplayContext();
    switch (value.type) {
        case "regular":
            return <span className={className}>{value.value}</span>;
            
        case "fraction":
            const { whole, num, den } = value.value;
            if (fractionAsNumber) {
                const value =  whole + num / den;
                return <span className={className}>{value}</span>;
            }

            if (whole) {
                return <span className={className}>{whole} {num}/{den}</span>;
            }

            return <span className={className}>{num}/{den}</span>;
    }
}

function QuantityValue({ value, className }: { value: IQuantityValue; className?: string; }) {
    switch (value.type) {
        case "number":
            return <CooklangNumber value={value.value} className={className}></CooklangNumber>;

        case "range":
            const start = <CooklangNumber value={value.start}></CooklangNumber>;
            const end = <CooklangNumber value={value.end}></CooklangNumber>;
            return <span className={className}>{start} - {end}</span>;

        case "text":
            return <span className={className}>{value.value}</span>;
    }
}

function ScalableValue({ value }: { value: IScalableValue }) {
    const { scale } = useRecipeDisplayContext();

    let quantityValue: IQuantityValue;
    let scaled = false;
    switch (value.type) {
        case "fixed":
            quantityValue = value.value;
            break;
        case "linear":
            const res = scaleQuantityValue(value.value, scale);
            quantityValue = res.value;
            scaled = res.scaled;
            break;
        case "byServings":
            if (scale) {
                if (scale.index !== undefined && value.value[scale.index]) {
                    quantityValue = value.value[scale.index];
                    scaled = true;
                }
                else {
                    quantityValue = value.value[0];
                }
            }
            else {
                quantityValue = value.value[0];
            }
            break;
    }

    if (scale?.factor === 1 && scale?.index === 0) {
        scaled = false;
    }

    return <QuantityValue value={quantityValue} className={scaled ? "recipe-ingredient-scaled" : ""}></QuantityValue>;
}

function RecipeStep({ step, image, recipe }: { step: Step; image?: string; recipe: CooklangRecipe; }) {
    let parts: ReactElement[] = [];
    let index = 0;
    for (const item of step.value.items) {
        switch (item.type) {
            case "text":
                parts.push(<span key={index}>{item.value}</span>);
                break;

            case "ingredient":
                const ingredient = recipe.ingredients[item.index];
                if (ingredient.quantity) {
                    const value = <ScalableValue value={ingredient.quantity.value}></ScalableValue>;

                    if (ingredient.quantity.unit) {
                        parts.push(<span key={index} className='recipe-ingredient'>{value} <span>{ingredient.quantity.unit}</span> {ingredient.name}</span>);
                    }
                    else {
                        parts.push(<span key={index} className='recipe-ingredient'>{value} {ingredient.name}</span>);
                    }
                }
                else {
                    parts.push(<span key={index} className='recipe-ingredient'>{ingredient.name}</span>);
                }
                break;

            case "cookware":
                const cookware = recipe.cookware[item.index];
                parts.push(<span key={index} className='recipe-cookware'>{cookware.name}</span>);
                break;

            case "timer":
                const timer = recipe.timers[item.index];
                if (timer.quantity) {
                    const value = <ScalableValue value={timer.quantity.value}></ScalableValue>;
                    if (timer.quantity.unit) {
                        parts.push(<span key={index} className='recipe-timer'>{value} {timer.quantity.unit}</span>);
                    }
                    else {
                        parts.push(<span key={index} className='recipe-timer'>{value}</span>);
                    }
                }
                break;
        }
        index++;
    }

    if (!image) {
        return <>{parts}</>;
    }

    return <div className='recipe-step-wrapper'>
        <div>{parts}</div>
        <img src={image}></img>
    </div>
}

export interface ParsedRecipeWithMetadata {
    id?: number;
    name: string;
    recipe: CooklangRecipe;
    image?: string;
    stepImages: Map<number, string>;
}

export interface RecipeProps {
    recipe: ParsedRecipeWithMetadata;
    servings?: number;
    fractionAsNumber?: boolean;
}

function Recipe({ recipe, servings, fractionAsNumber }: RecipeProps) {
    const inner = recipe.recipe;

    let recipeServings = inner.metadata.special.servings ?? [];
    let baseServings = recipeServings[0] ?? 1;
    let scale: ScaleTarget | undefined = undefined;
    if (servings !== undefined) {
        let servingsIndex = recipeServings.indexOf(servings);
        scale = {
            factor: servings / baseServings,
            index: servingsIndex === -1 ? undefined : servingsIndex
        };
    }

    const displayContext: RecipeDisplayContext = {
        fractionAsNumber: !!fractionAsNumber,
        scale
    };

    let infoContent: ReactElement[] = [];
    {
        const special = inner.metadata.special;
        if (special.source) {
            if (special.source.url) {
                infoContent.push(<li key='source'><a href={special.source.url}>{special.source.url}</a></li>);
            }
            else {
                infoContent.push(<li key='source'>{special.source.name}</li>);
            }
        }
        if (servings && special.servings) {
            if (servings === special.servings[0]) {
                infoContent.push(<li key='servings'>{special.servings[0]} portion(er)</li>);
            }
            else {
                infoContent.push(<li key='servings'><s>{special.servings[0]}</s> <span className='recipe-ingredient-scaled'>{servings}</span> portion(er)</li>);
            }
        }
        else if (servings) {
            infoContent.push(<li key='servings'><span className='recipe-ingredient-scaled'>{servings}</span> portion(er)</li>);
        }
        else if (special.servings) {
            infoContent.push(<li key='servings'>{special.servings[0]} portion(er)</li>);
        }
        const time = special.time;
        if (time) {
            if (typeof time === "number") {
                infoContent.push(<li key='time'>{time} min</li>);
            }
            else {
                infoContent.push(<li key='prep-time'>Förberedelse: {time.prep_time} min</li>);
                infoContent.push(<li key='cook-time'>Tillagning: {time.cook_time} min</li>);
            }
        }
    }

    const hasCookware = inner.cookware.length !== 0;
    let stepIndex = 0;
    return (
        <RecipeDisplayContext.Provider value={displayContext}>
            <div className='recipe-wrapper'>
                <Stack direction='row' alignItems='center'>
                    <h2>{recipe.name}</h2>
                    <Box flexGrow='1' justifyContent='right' display='flex'>
                        {recipe.id !== undefined ? buildEditRecipe({ id: recipe.id }) : <></>}
                    </Box>
                </Stack>
                <Box display='flex' justifyContent='center'>
                    {recipe.image ? <img src={recipe.image} className='recipe-header-img'></img> : <></>}
                </Box>
                <h3>Info</h3>
                <ul>
                    {infoContent}
                </ul>
                <Stack direction={{ sm: 'row' }}>
                    <Box width={{ sm: '45%' }}>
                        <h3>Ingredienser</h3>
                        <ul>
                            {inner.ingredients.map((i, index) => {
                                if (!i.quantity) {
                                    return <li key={index}>{i.name}</li>;
                                }
                                const value = <ScalableValue value={i.quantity.value}></ScalableValue>;

                                if (i.quantity.unit) {
                                    return <li key={index}>{value} <span>{i.quantity.unit}</span> {i.name}</li>
                                }

                                return <li key={index}>{value} {i.name}</li>;
                            })}
                        </ul>
                    </Box>
                    <Box sx={{ flexGrow: '1', display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
                        <Divider orientation='vertical'></Divider>
                    </Box>
                    <Box width={{ sm: '45%' }} display={{ sm: 'block', xs: hasCookware ? "block" : "none"  }}>
                        <h3>Utrustning</h3>
                        {hasCookware ?
                            <ul>
                                {inner.cookware.map((c, index) => {
                                    return <li key={index}>{c.name}</li>
                                })}
                            </ul> :
                            <i>Inget</i>
                        }
                    </Box>
                </Stack>

                {
                    inner.sections.map((s, index) => {
                        return <div key={index}>
                            <h3>{s.name || "Att göra"}</h3>
                            <ol className='recipe-ordered-list'>
                                {s.content.map((c, index) => {
                                    stepIndex += 1;
                                    switch (c.type) {
                                        case "text":
                                            return <li key={index}>{c.value}</li>;

                                        case "step":
                                            return <li key={index}><RecipeStep recipe={inner} image={recipe.stepImages.get(stepIndex)} step={c}></RecipeStep></li>;
                                    }
                                })}
                            </ol>
                        </div>;
                    })
                }
            </div>
        </RecipeDisplayContext.Provider>
    );
}

export default Recipe;