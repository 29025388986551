import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { imageIdToUrl, Recipe } from '../data';
import { Box, Stack, styled } from '@mui/material';

export interface RecipeCardProps {
    recipe: Recipe;
    link?: string;
}

const BoxMain = styled(Box)(() => ({
    overflow: "hidden",
    position: 'relative',
    height: "150px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  
    // "&:after": {
    //   content: '""',
    //   position: "absolute",
    //   bottom: 0,
    //   display: "block",
    //   width: "100%",
    //   height: "100%",
    //   background: "linear-gradient(to top, #014a7d, rgba(0,0,0,0))",
    // },
}));

const StyledCardMedia = styled(CardMedia)({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    backgroundPosition: "center",
});

const RowName = styled("div")(({ theme }) => ({
    display: "flex",
    minWidth: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    margin: 0,
    position: "relative",
}));

export default function RecipeCard({ recipe, link }: RecipeCardProps) {
    const imageUrl = imageIdToUrl("medium", recipe.image_id);

    const tags = recipe.tags || [];
    console.log(recipe);

    return <Card sx={{ width: 200 }}>
        <CardActionArea href={link || ""} sx={{ height: '100%' }}>
            <Stack direction='column'>
                <BoxMain>
                    <StyledCardMedia
                        image={imageUrl}
                    />
                    <Stack
                        direction='row'
                        sx={{ zIndex: 1, position: 'relative', padding: '10px' }}
                        spacing='5px'
                        flexWrap='wrap-reverse'
                        useFlexGap
                    >
                        {tags.map(tag => <span key={tag} className='recipe-tag'>{tag}</span>)}
                    </Stack>
                </BoxMain>
                <RowName>
                    <Typography component="div" sx={{ textAlign: 'left' }}>
                        {recipe.name}
                    </Typography>
                </RowName>
            </Stack>
        </CardActionArea>
    </Card>;
}
