import React, { ReactElement, useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { CooklangRecipeWithMetadata, cookRecipe, imageIdToUrl } from '../data';
import Recipe, { ParsedRecipeWithMetadata } from '../components/Recipe';
import { Box, Paper } from '@mui/material';

function withImageUrls(recipeId: number, recipe: CooklangRecipeWithMetadata | undefined): ParsedRecipeWithMetadata | undefined {
    if (!recipe) return undefined;

    const stepImages = new Map<number, string>();
    for (const step of recipe.step_images) {
        stepImages.set(step.step + 1, imageIdToUrl("medium", step.image_id)!);
    }

    return {
        id: recipeId,
        name: recipe.name,
        recipe: recipe.recipe,
        image: imageIdToUrl("large", recipe.image_id),
        stepImages
    };
}

export interface LoaderData {
    recipe: ParsedRecipeWithMetadata | undefined;
    servings?: number;
}

export async function loader({ params, request }: LoaderFunctionArgs): Promise<LoaderData> {
    const recipeId = params.recipeId;
    if (!recipeId) {
        throw new Error("Missing recipeId");
    }
    const url = new URL(request.url);
    const parsedServings = parseInt(url.searchParams.get("servings") || "", 10);
    const servings = Number.isFinite(parsedServings) ? parsedServings : undefined

    const recipeIdNum = parseInt(recipeId, 10);
    const recipe = await cookRecipe(recipeIdNum);
    return {
        recipe: withImageUrls(recipeIdNum, recipe),
        servings,
    };
}

function RecipeView() {
    const { recipe, servings } = useLoaderData() as LoaderData;

    let inner: ReactElement;
    if (recipe) {
        inner = <Recipe recipe={recipe} servings={servings} fractionAsNumber={false}></Recipe>;
    }
    else {
        inner = <div>
            <h3>Recept hittades inte</h3>
            Verifera att länken är giltig.
        </div>;
    }

    return (
        <Box sx={{ backgroundColor: 'rgb(223, 239, 255)', pb: { md: '15px' }, minHeight: "100%", flexGrow: '1' }}>
            <Paper
                square={true}
                elevation={8}
                sx={{
                    backgroundColor: 'white',
                    width: { md: '800px' },
                    paddingLeft: { md: '30px' },
                    paddingRight: { md: '30px' },
                    paddingBottom: '30px',
                    ml: 'auto',
                    mr: 'auto',
                }}
            >
                {inner}
            </Paper>
        </Box>
    );
}

export default RecipeView;
